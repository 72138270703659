// assets
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import CommuteOutlinedIcon from '@mui/icons-material/CommuteOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import RvHookupOutlinedIcon from '@mui/icons-material/RvHookupOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';

// constant
const icons = {
    GroupWorkOutlinedIcon,
    CommuteOutlinedIcon,
    DescriptionOutlinedIcon,
    AccountBalanceWalletOutlinedIcon,
    RvHookupOutlinedIcon,
    EngineeringOutlinedIcon,
    AssessmentIcon
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const operations = {
    id: 'operations',
    title: 'Operations',
    caption: 'Business operations',
    type: 'group',
    children: [
        {
            id: 'dispatch',
            title: 'Dispatch',
            type: 'item',
            url: '/operations/consignments',
            icon: icons.GroupWorkOutlinedIcon
        },
        {
            id: 'expenses',
            title: 'Dispatch Expenses',
            type: 'item',
            url: '/operations/consignment/expenses',
            icon: icons.GroupWorkOutlinedIcon
        },
        {
            id: 'shunting',
            title: 'Shunting',
            type: 'item',
            url: '/operations/fleet/expenses/shunting',
            icon: icons.RvHookupOutlinedIcon
        },
        {
            id: 'consignmentrpt',
            title: 'Dispatch Report',
            type: 'item',
            url: '/operations/consignment/report',
            icon: icons.AssessmentIcon
        }
        /*
        {
            id: 'trip',
            title: 'Trips',
            type: 'item',
            url: '/operations/trips',
            icon: icons.CommuteOutlinedIcon
        },
        {
            id: 'crew',
            title: 'Crew',
            type: 'item',
            url: '/operations/crew',
            icon: icons.EngineeringOutlinedIcon
        }
        */
    ]
};

export default operations;
