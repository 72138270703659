import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../views/pages/authentication/authSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: 'https://haulier.onrender.com/api/',
    // baseUrl: 'http://localhost:8000/api/',
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('Content-Type', 'application/json');
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    // console.log(result);
    if (result?.error?.status === 401) {
        console.log('sending refresh token');
        // send refresh token to get new access token
        const refreshResult = await baseQuery('/auth/refresh', api, extraOptions);
        console.log(refreshResult);
        if (refreshResult?.data) {
            const user = api.getState().auth.user;
            // store the new token
            api.dispatch(setCredentials({ ...refreshResult.data, user }));
            // retry the original query with new access token
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logOut());
        }
    }

    return result;
};

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'Clients',
        'Users',
        'Cargo',
        'Routes',
        'RouteRates',
        'Categories',
        'Invoices',
        'Consignments',
        'ConsignmentCats',
        'Trips',
        'Fleet',
        'TruckInspection',
        'CargoInsurance',
        'TruckOwner',
        'TruckSize',
        'Expenses',
        'ExpenseCategories',
        'ConsignmentExpenseCategories',
        'ConsignmentExpenses',
        'Crew',
        'Receipts',
        'Containerdepots',
        'FuelRates',
        'FuelRateTowns',
        'FuelCategories',
        'Account',
        'Shunting',
        'ConsignmentReport',
        'OrderNumber',
        'truckreport',
        'TruckExpensesCategories'
    ],
    keepUnusedDataFor: 60,
    endpoints: (builder) => ({})
});
